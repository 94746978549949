const apiBaseURL = 'https://dlapi.akashcodecafe.tech';
// const apiBaseURL = 'http://192.168.31.222:3005';
// const apiBaseURL = 'http://192.168.0.6:3005';

console.log('apiBaseURL --->', apiBaseURL);

export const URL = {
    LOGIN_IN_URL: `${apiBaseURL}/api/v1/member/login`,
    LOG_OUT_URL: `${apiBaseURL}/api/v1/member/logout`,
    VERIFY_TOKEN_URL: `${apiBaseURL}/api/v1/member/verify-token`,
    DEALER_LIST_URL: `${apiBaseURL}/api/v1/dealer/assigned-dealer-list`,
    PRODUCT_LIST_URL: `${apiBaseURL}/api/v1/product/product-list`,
    PLACE_ORDER_URL: `${apiBaseURL}/api/v1/order/place-order`,
    PRODUCT_ORDER_LIST_URL: `${apiBaseURL}/api/v1/order/product-order-list`,
    DEALER_ORDER_LIST_URL: `${apiBaseURL}/api/v1/order/dealer-order-list`,
    DELETE_ORDER: `${apiBaseURL}/api/v1/order/delete-order`,
    ACCEPT_PAYMENT_URL: `${apiBaseURL}/api/v1/transaction/accept-payment`,
    TODAY_TRANSACTION_URL: `${apiBaseURL}/api/v1/transaction/today-transaction`,
    ANALYTICS_URL: `${apiBaseURL}/api/v1/dashboard/analytics`,
    CHART_DATA_URL: `${apiBaseURL}/api/v1/dashboard/chart`,
    CHART_URL: `${apiBaseURL}/api/v1/dashboard/chart`,
    TODAY_COLLECTION_URL: `${apiBaseURL}/api/v1/collection/today-collection`,
};

export const shopImageUrl = 'https://i.imghippo.com/files/3e0yU1716627503.png';
export const personImageUrl = 'https://i.imghippo.com/files/Ynrz01716627559.jpg';