import React, { lazy, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { useAuth } from './context/AuthContext'
import { URL } from './utils/apiURL'
const { VERIFY_TOKEN_URL } = URL;

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./components/Authentication/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./components/Authentication/ForgotPassword'))

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const { user, setVerifiedUser } = useAuth();


  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    if (!user && token) {
      const response = await fetch(VERIFY_TOKEN_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok && response.status === 200) {
        const userData = await response.json();
        setVerifiedUser(userData.data);
        setAuthenticated(true);
      } else {
        // Authentication failed, handle error
        localStorage.removeItem('token');
        setAuthenticated(false);
      }
    }
  }

  useEffect(() => {
    // Check if the user is authenticated (e.g., by checking the token)
    const token = localStorage.getItem('token');
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
    verifyToken()
  }, [user, setVerifiedUser]);


  const handleLogout = () => {
    // Clear token and set authentication state to false on logout
    localStorage.removeItem('token');
    setAuthenticated(false);
  };


  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login">
            {authenticated ? <Redirect to='/app' /> : <Login />}
          </Route>
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Protected routes */}
          <Route path="/app">
            {authenticated ? <Layout onLogout={handleLogout} /> : <Redirect to="/login" />}
          </Route>

          {/* Redirect all other routes to login */}
          <Redirect to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
