import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
    name: "layout",
    initialState: {
        sidebarShow: true,
        theme: 'light',
    },
    reducers: {
        changeState(state, action) {
            let { type, ...rest } = action.payload
            switch (type) {
                case 'set':
                    return { ...state, ...rest }
                default:
                    return state
            }
        },
    }
});

export const { changeState } = layoutSlice.actions;
export default layoutSlice.reducer;