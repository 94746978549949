import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import layoutSlice from "./slices/layoutSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        layout: layoutSlice
    },
})
export default store;