import React from 'react'
import { CircularProgress } from '@material-ui/core';

function ThemedSuspense() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
      <CircularProgress />
    </div>
  )
}

export default ThemedSuspense
