import React, { createContext, useState, useContext, useCallback } from 'react';
import { URL } from '../utils/apiURL'
const { LOGIN_IN_URL } = URL

// Create AuthContext
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (email, password) => {
    try {
      const response = await fetch(LOGIN_IN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok && response.status === 200) {
        setUser(data.data); // Assuming API returns user info along with token
        localStorage.setItem('token', data.data.authToken); // Store token in localStorage
        return {
          success: true,
          ...data
        }
      }
      return {
        success: false,
        ...data
      }
    } catch (error) {
      console.error('Login failed', error);
      return { success: false, message: 'Login failed due to an error' };
    }
  };

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('token');
  }, []);

  const setVerifiedUser = useCallback((user) => {
    setUser(user);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, setVerifiedUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
